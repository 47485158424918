import * as React from 'react';
import styles from "./Welcome.module.scss"
import { TextInput } from '@patternfly/react-core';
import { AllService } from 'services';
import * as moment from "moment";
import "moment/locale/en-gb";
import classNames from 'classnames';
import { Spinner } from '@patternfly/react-core/dist/esm/experimental';

export interface WelcomePageProps {
  readonly dumm?: boolean;
  //readonly url?: string;
  //readonly roles?: Role[];
}

export interface WelcomePageState {
  readonly services: ServiceType[];
  readonly posts: any;
  readonly piliers: PostType[];
  search: string;
  readonly filtered_posts:any;
  readonly loading:boolean;
}

export interface ServiceType{
  title: string;
  image?: string;
  description: any;
  actived?:boolean;
  
}
export interface PostType{
  id:any;
  title: string;
  image?: string;
  description: any;
  actived?:boolean;
}

class WelcomeComponent extends React.Component<WelcomePageProps,WelcomePageState>  {
  readonly state : WelcomePageState = {
    services : [],
    posts :{Countries:[]},
    piliers:[],
    search : "",
    filtered_posts: {Countries:[]},
    loading: false
  }
  handleSearch = search => {
      this.setState({search});
      const {posts={Countries:[]}} = this.state;

      var all = posts.Countries.map(post => {
          if(post.Country.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1){
              return post;
          }
      }).filter(c => c !== undefined);
      this.setState({filtered_posts:{
        Countries: all
      }});
  }

  componentDidMount=()=>{
    this.getPrincingPlans();
  }
  //static contextType=store

  getPrincingPlans(){
    const {openNotification, formatMessage} = this.context;
    let getPlans;
    this.setState({loading:true});
    getPlans = AllService.getsummary();
    getPlans.then(body=>{
      if (body.status === undefined) {
        openNotification(
          formatMessage("server_unavailable"),
          "error"
        );
        return false;
      }
      if (body.status === 200 || body.status === 201) {
        let data = body.json();
        data.then(data => {
          this.setState({posts: data})
        });
        this.setState({loading: false})
      } else {
        let status = body.status;
        let data = body.json();
        data.then(body => {
          if (status === 500) {
            openNotification(body.message, "error");
          } else {
            openNotification(body.message, "warning");
          }
          this.setState({loading: false})
        });
      }
      this.setState({loading: false})
    })
  }

  

  render(){
    const { posts={Countries:[]}, filtered_posts={Countries:[]}, piliers, search, loading=true} = this.state;

    return (
      <div className={styles.section}> 
        <div className={styles.search}>
          <TextInput
            className={styles.textfield}
            label=""
            placeholder="Search a country"
            onChange={this.handleSearch}
            isRequired={true}
            name="entityName"
            value={search}
          />
        </div>
        <a href='#'>Last updated: {moment.utc(posts.Date).format('LLLL')}</a>
        <br/>
        <div className={styles.list}>
         {loading ? 
          <span><Spinner size="md"/> chargement</span>
         :
          <span>
            {Boolean(search && filtered_posts.Countries.length===0) && 
            <span className={styles.info}>No elements found... <small>Write the country name in english please</small></span>}
          </span>
         }
         {(loading === false && posts.Countries.length === 0)  ? 
          <span>
            <span className={styles.info}>No elements found... <small>Check your internet connection</small></span>
          </span>:null}
         {(search ? filtered_posts.Countries : posts.Countries).filter(country => country.Slug).map(item => (<div className={styles.item}>
            <div className={styles.rows}>
              <span className={styles.name}>Country</span>
              <span className={styles.value}>
                {item.Country}</span>
            </div>
            <div className={styles.rows}>
              <span className={styles.name}>New confirmed</span>
              <span className={styles.value}>{item.NewConfirmed}</span>
            </div>
            <div className={styles.rows}>
              <span className={styles.name}>Total confirmed</span>
              <span className={styles.value}>{item.TotalConfirmed}</span>
            </div>
            <div className={styles.rows}>
              <span className={styles.name}>New deaths</span>
              <span className={classNames(styles.value,styles.red)}>
                {item.NewDeaths}</span>
            </div>
            <div className={styles.rows}>
              <span className={styles.name}>Total deaths</span>
              <span className={classNames(styles.value,styles.red)}>{item.TotalDeaths}</span>
            </div>
            <div className={styles.rows}>
              <span className={styles.name}>New recovered</span>
              <span className={classNames(styles.value,styles.green)}>{item.NewRecovered}</span>
            </div>
            <div className={styles.rows}>
              <span className={styles.name}>Total recovered</span>
              <span className={classNames(styles.value,styles.green)}>{item.TotalRecovered}</span>
            </div>
          </div>))}
        </div>
      </div>
    );
  }
}

const WelcomePage = (WelcomeComponent);

export { WelcomePage }