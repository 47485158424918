import * as React from 'react';
import { withToast } from 'react-awesome-toasts';
import notificationStore from 'stores/notification.store';
import { map, skip } from 'rxjs/operators'

const ToastManager = ({ toast }) => {

  React.useEffect(() => {
    let sub = notificationStore
      .stateChanged
      .pipe(
        skip(1),
        map(notif => {
          let props: any = {
            text: notif.message,
            variant: notif.type,
            ariaLabel: notif.ariaLabel || notif.message,
          }

          if (notif.action) {
            props = {
              ...props,
              actionText: notif.action!.text,
              onActionClick: (...e) => {
                notif.action!.method(...e)
                toast.hide(...e)
              },
            }
          }

          return props
        })).subscribe(toastProps => toast.show(toastProps))

    return sub.unsubscribe
  }, [])

  return <></>;
}

//@ts-ignore
export default withToast(ToastManager);