import React from 'react';
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import {
  EntryPage,
} from './pages';
import { ToastProvider } from 'react-awesome-toasts';

import {
  Internationalisation, NotificationManager,
} from './components';

const App = (props) => {
  return (
    <Internationalisation locale={'fr'}>
      <ToastProvider>
        <NotificationManager />
        <Router>
          <EntryPage />
        </Router>
      </ToastProvider>
    </Internationalisation>
  );
}

export default App;
