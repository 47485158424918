import React from 'react';
import { Switch, Route } from "react-router-dom";
import { sortRoles, parseRoles } from 'utilities'
import { entryPageRoutes } from 'routes'
import { Role } from 'constants/roles';
import { WelcomePage, NotFoundPage } from 'pages';

interface EntryPageRouteProps {
  readonly url: string;
  readonly roles: Role[];
}

const EntryPageRouteComponent: React.FC<EntryPageRouteProps> = (props) => {
  let {
    url,
    roles
  } = props
  
  //const defaultRoutes = sortRoles(roles)[0].default_route;
  //console.log({ sorted_routes: sortRoles(roles) })

  return (
      <Switch>
        <Route
            path={"/"}
            exact={true}
            component={WelcomePage}
          />
        {entryPageRoutes(url, parseRoles(roles)).map(route => (
            <Route
              path={route.to}
              exact={route.exact}
              component={route.component}
            />
        ))}
         <Route
            path={"*"}
            exact={false}
            component={NotFoundPage}
          />
      </Switch>
  );
}

export {
  EntryPageRouteComponent as EntryPageRoute
}