import * as React from "react";
import styles from "./Toolbar.module.scss";
import { entryPageRoutes } from "routes";
import { Link, NavLink } from "react-router-dom";
import Logo from "assets/imgs/logo.jpg";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Nav,
  NavList,
  NavItem,
  NavVariants,
  PageHeader,
  Brand
} from "@patternfly/react-core";
import { Helmet } from "react-helmet";
//import { SideBar } from "components";
import classnames from 'classnames';

export interface BaseProps {
  readonly dumm?: boolean;
  readonly open?: boolean;
  readonly roles: string[];
  readonly url?: string;
  readonly activeItem: string;
}

const ToolBarMenuComponent: React.FC<BaseProps> = props => {
  const avatar_url = "https://api.adorable.io/avatars/134/abott@adorable";
  const [isNavOpen, setNavOpen] = React.useState(false);
  const { formatMessage } = useIntl();

  const url = props.url;

  const {
    //url,
    roles
  } = props;

  const onNavToggle = () => {
    setNavOpen(!isNavOpen);
    console.log({ isNavOpen });
  };
  const [activeItem, setActiveItem] = React.useState(props.activeItem);
  const [activeTitle, setActiveTitle] = React.useState(props.activeItem);

  function onNavSelect(result) {
    setActiveItem(result.to);
    setActiveTitle(result.title);
    //console.log(activeItem);
  }

  const NavSection = (
    <Nav onSelect={onNavSelect} aria-label="Nav">
      <NavList variant={NavVariants.tertiary}>
        {entryPageRoutes("", roles).map(route => (
          <NavItem
            to={route.to}
            component="a"
            itemId={route.title}
            isActive={
              /*activeItem === route.to ||*/ url === route.to ||
              (url === "/" && route.to === "/welcome")
            }
            onClick={() => onNavSelect(route)}
            className={classnames(styles.navitem)}
          >
            <Link className={classnames(styles.link,url === route.to && styles.active)} to={route.to!}>
              <FormattedMessage id={route.title!} />
            </Link>
           {/*<span className={styles.indicator}>
              &nbsp;
            </span>*/}
          </NavItem>
        ))}
      </NavList>
      {/*false && <><LanguageManager />
      <Button className={styles.btn_started}>Get Started</Button>
      </>*/}
    </Nav>
  );
  let title = <FormattedMessage id={activeTitle} />;
  let item = entryPageRoutes("", roles).filter(
    route => route.to === activeItem
  )[0];
  return (
    <>
      <Helmet>
        <title>
          {item && item.title
            ? formatMessage({ id: item.title }) + "- PowerPro"
            : "PowerPro"}{" "}
        </title>
      </Helmet>
      <PageHeader
        //logo={<Brand className={styles.logo_container} src={Logo} alt="Patternfly Logo" />}
        logo={<span>V-19</span>}
        //toolbar={PageToolbar}
        //avatar={<Avatar src={Logo} alt="Avatar image" />}
        topNav={NavSection}
        showNavToggle
        isNavOpen={isNavOpen}
        title={"Centrale Magazine"}
        onNavToggle={onNavToggle}
      />
      {/*false && <SideBar isNavOpen={isNavOpen} onNavToggle={onNavToggle} />*/}
    </>
  );
};

export { ToolBarMenuComponent as ToolBarMenu };
