import { ObservableStore } from '@codewithdan/observable-store';
import { IBaseStore } from './IStore';

class NotificationStore extends ObservableStore<INotification> implements IBaseStore {

    public static ACTIONS = {
        NEW_NOTIFICATION: 'NOTIFICATION:NEW',
    }

    constructor() {
        super({ trackStateHistory: true });
    }

    newNotification = (notification: INotification) => {
        this.setState(notification, NotificationStore.ACTIONS.NEW_NOTIFICATION)
    }

    /**Since we are only showing notification, no crucial data is stored here
     * That needs to be gotten rid of when the user logs out so we are doing 
     * nothing on logout.
     */
    onLogOut = () => 'ok'
}

export default new NotificationStore()

export interface INotification {
    type: 'warning' | 'error' | 'success' | 'default',
    message: string,
    action?: {
        text:string,
        method:(...e) => any,
    },
    ariaLabel?:string,
}