import React from "react";
import { ROLE_ADMINISTRATOR } from "constants/roles";
import styles from "../../components/toolbar/Toolbar.module.scss";
import { ToolBarMenu  } from "components";
import { parseRoles } from "utilities";
import { EntryPageRoute } from "routes";
import {
  LocationArrowIcon,
  EnvelopeIcon,
  PhoneAltIcon,
  PhoneSquareAltIcon
} from "@patternfly/react-icons";
interface ContainerPageRouteProps {
  location: any;
}
interface ContainerPageRouteState {
  url: any;
}

export const contacts = [
  {
    title: "Notre adresse",
    icon: <LocationArrowIcon/>,
    label: "12 Avenue Kenedi, Yaoundé City, CM 58D 140"
  },
  {
    title: "Notre courriel",
    icon: <EnvelopeIcon />,
    label: "info@powerpro.com"
  },
  {
    title: "Phone",
    icon: <PhoneSquareAltIcon/>,
    label: "+237 656115627"
  }
];

class ContainerPageRoute extends React.Component<
  ContainerPageRouteProps,
  ContainerPageRouteState
> {
  state: ContainerPageRouteState = {
    url: ""
  };
  componentDidMount() {
    let url = this.props.location.pathname;
    this.setState({ url });
  }

  render() {
    const { url } = this.state;
    //console.log({url})
    let url2 = this.props.location.pathname;
    return (
      <>
        <div className={styles.wrapper}>
          <ToolBarMenu
            url={url2}
            activeItem={url2}
            roles={parseRoles([ROLE_ADMINISTRATOR])}
          />
        </div>
        <div className="app_content">
          <EntryPageRoute url={url2} roles={[ROLE_ADMINISTRATOR]} />
        </div>
      </>
    );
  }
}

export default ContainerPageRoute;
