import {
    WelcomePage
} from 'pages';
import {
    parseRoles,
} from 'utilities';
import {
    ROLE_ADMINISTRATOR,
} from "constants/roles";

interface Route {
    to?: string;
    title?: string;
    component?: any;
    roles?: string[];
    title_type?: boolean;
    exact?: boolean;
}

type Routes = (url: string, roles: string[]) => Route[]

const getRoutesPerRoles: Routes = (baseRoute = '', roles: string[]) => {
    const routes: Route[] = [
        {
            title_type: false,
            to: '/',
            title: "routes.welcome",
            roles: parseRoles([
                ROLE_ADMINISTRATOR,
            ]),
            component: WelcomePage,
            exact:true
        }, /* General section */
      /*  {
            title_type: false,
            to: '/services',
            title: "routes.services",
            roles: parseRoles([
                ROLE_ADMINISTRATOR,
            ]),
            component: ServicesProductsPage,
            exact:true
        },
        {
            title_type: false,
            to: '/contacts',
            title: "routes.contacts",
            component: ContactPage,
            roles: parseRoles([
                ROLE_ADMINISTRATOR,
            ]),
            exact:true
        },
        {
            exact: true,
            to: '/blogs',
            title: "routes.blog",
            component: BlogsPage,
            roles: parseRoles([
                ROLE_ADMINISTRATOR
            ])
        }*/
    ]
    //const parsedRoutes = routes.map(route => ({ ...route, to: `${baseRoute}${route.to}` }))
    const parsedRoutes = routes.map(route => ({ ...route, to: `${route.to}` }))

    const filteredRoutesPerRole = parsedRoutes.filter(
        route => roles.some(role => route.roles!.includes(role.toLocaleLowerCase()))
    );
    //console.log({filteredRoutesPerRole});

    return (
        filteredRoutesPerRole
    )
}

export {
    getRoutesPerRoles as entryPageRoutes
}