import * as React from "react";
import styles from "./NotFound.module.scss";
import { RouteComponentProps } from "react-router";
import { Button } from "@patternfly/react-core";
import { Link } from "react-router-dom";
export interface NotFoundProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

interface NotFoundState {
  readonly dumm: boolean;
}

const NotFoundComponent: React.FC<NotFoundProps> = props => {
  const url:any= '/'
  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.title}>Oups!</h2>
        <p className={styles.paragraph}>
          La page que vous recherchez n'existe pas. Il a peut-être été déplacé
          ou supprimé complètement. Vous pouvez peut-être revenir à la page
          d'accueil.
        </p>
        <Link to={url} className={styles.button}>Retour à l'accueil</Link>
      </div>
    </div>
  );
};

export { NotFoundComponent as NotFoundPage };
